<template>
    <b-modal size="xl" v-model="showReportDetail" id="reportDetailModal" :title="reportTitle" @ok="printReport()" ok-only :ok-title="$t('reports.reportDetailModal.printReport')">
		<h2 v-if="formatDate(reportPeriod.from) !== 'Invalid Date'">
        {{$t('general.period')}}: {{formatDate(reportPeriod.from)}} - {{formatDate(reportPeriod.to)}}
        </h2>
        <div class="spacer m" />
        <div v-if="loading">
          <LoadingAnimation />
        </div>
        <div v-if="this.reportData.histories && this.reportData.histories.length > 0">
            <b-table 
                :sort-by.sync="sortBy" 
                @sort-changed="sortingChanged"
                ref="emptySoonWineList"
                :items="reportData.histories" 
                :fields="fields"
                sticky-header
                class="reportDetailsTable"
            >
            
               <!-- A custom formatted column -->
                <template #cell(venueWine.createdAt)="data">
                {{ formatDate(data.value, true) }}
                </template>
                <template #cell(descriptionTitle)="data">
                {{data.value.text + ', ' + ( data.value.regionLocaleKey !== null ? $t('regions.' + data.value.regionLocaleKey) : data.value.regionTitle ) }}
                </template>

                <template #cell(createdAt)="data">
                {{ formatDate(data.value, true) }}
                </template>

                <template #cell(changedByUser.username)="data">
                    <div v-if="data.value">
                        {{ data.value }}
                    </div>
                    <div v-else>
                        {{$t('general.deletedEmployee')}} 
                    </div>
                </template>

                <template #cell(purchasePriceAvgGenerated)="data">
                    <div class="cellWithMinWidth">
                        {{$t('general.netPrice')}}: {{ data.value.toFixed(2) + " " + getCurrency }} <br/>
                        {{$t('general.grossPrice')}}: {{ getGrossPrice(data.value) + ' ' + getCurrency }}
                    </div>    
                </template>

                <template #cell(addedPurchasePriceAvgGenerated)="data">
                    <div class="cellWithMinWidth">
                        {{$t('general.netPrice')}}: {{ data.value.toFixed(2) + " " + getCurrency }} <br/>
                        {{$t('general.grossPrice')}}: {{ getGrossPrice(data.value) + ' ' + getCurrency }}
                    </div>
                </template>

                <template #cell(discount)="data">
                    <span v-if="data.value === true">{{ $t('general.buttons.yes') }}</span>
                    <span v-if="data.value !== true">{{ $t('general.buttons.no') }}</span>
                </template>

                <template #cell(revenueAll)="data">
                    <div class="cellWithMinWidth">
                        {{$t('general.netPrice')}}: {{ getNetPrice(data.value) + " " + getCurrency }} <br/>
                        {{$t('general.grossPrice')}}: {{ data.value.toFixed(2) + ' ' + getCurrency }}   
                    </div>
                </template>

                <template #cell(addedRevenueAll)="data">
                    <div class="cellWithMinWidth">
                        {{$t('general.netPrice')}}: {{ getNetPrice(data.value) + " " + getCurrency }} <br/>
                        {{$t('general.grossPrice')}}: {{ data.value.toFixed(2) + ' ' + getCurrency }}
                    </div>
                </template>

                <template #cell(profitGenerated)="data">
                    <div class="cellWithMinWidth">
                        {{$t('general.netPrice')}}: {{ getNetPrice(data.value) + " " + getCurrency }} <br/>
                        {{$t('general.grossPrice')}}: {{ data.value.toFixed(2) + ' ' + getCurrency }}
                    </div>
                </template>

                <template #cell(addedProfitGenerated)="data">
                    <div class="cellWithMinWidth">
                        {{$t('general.netPrice')}}: {{ getNetPrice(data.value) + " " + getCurrency }} <br/>
                        {{$t('general.grossPrice')}}: {{ data.value.toFixed(2) + ' ' + getCurrency }}
                    </div>
                </template>

                <template #cell(addedProfit)="data">
                   {{ data.value + " " + getCurrency }} {{$t('general.grossPrice')}}: {{ data.value + ' ' + getCurrency }}
                </template>
                <template #cell(causeOfReduction)="data">
                    <span v-if="data.value">{{ $t('detailPanel.reduceModal.causes.' + data.value)  }}</span>
                    <span v-else>{{ $t('general.notDefined' + data.value)  }}</span>
                </template>
                <template slot="bottom-row">
                    <td  style="text-align: right" :colspan="lastRowColspan()">{{this.$t('general.total')}}: </td>
                    <td>{{lastRowTotalAmount()}}</td> 
                    <td>
                        <p>{{lastRowTotalPurchasePrice()+ " " + getCurrency}}</p>
                        <p>{{getGrossPrice(lastRowTotalPurchasePrice())+ " " + getCurrency}}</p>
                    </td> 
                    <td>
                        <p>{{getNetPrice(lastRowTotalSellingPrice())+ " " + getCurrency}}</p>
                        <p>{{lastRowTotalSellingPrice()+ " " + getCurrency}}</p>
                    </td> 
                    <td>
                        <p>{{getNetPrice(lastRowProfit())+ " " + getCurrency}}</p>
                        <p>{{lastRowProfit()+ " " + getCurrency}}</p>
                    </td>
                </template>
               
            </b-table>
        </div>
        <div v-else-if="!loading">
          <p>{{this.$t('general.noData')}}</p>
        </div>
    </b-modal>
</template>

<script>
    import { mapGetters } from 'vuex'
    import DateTimeService from '@/services/DateTimeService'
    import { getVenueCurrency, getNetPrice, getGrossPrice } from '@/utils/functions'
    import LoadingAnimation from '@/components/LoadingAnimation.vue'
    import DataService from '@/services/StrapiService'


    export default {
        name: 'reportDetailModal',
        data: function () {
            return {
                loading: true,
                showReportDetail: false,
                reportData: {},
                reportType: "",
                reportPeriod: {},
                reportTitle: '',
                sortBy: 'createdAt'
            }
        },
        created () {
        },
        methods: {  
            getNetPrice,
            getGrossPrice,
            async showModal(report) {
                this.loading = true
                this.reportData = {}
                this.reportType = ""
                this.showReportDetail = true
                this.reportPeriod = {
                    from: report.attributes.dateFrom,
                    to: report.attributes.dateTo
                }
                this.reportTitle = report.attributes.title
                
                await DataService.findVenueWineHistoryReports(report.attributes.filterQuery).then((response) => {
                    this.reportData = response['data'].data
                    this.reportType = report.attributes.type
                    this.loading = false
                }).catch((err) => {
                    console.log(err)
                    this.loading = false
                })
            },
            formatDate(date, time) {
                var newDate = new Date(date)
                var parsedDatetime = DateTimeService.dateToFormattedstring(newDate, time, this.$i18n.locale)
                return parsedDatetime
            },
            printReport() {
                window.print()
            },
            sortingChanged (ctx) {
                this.sortBy = ctx.sortBy
                this.refreshTable()
            },
            refreshTable () {
                this.$root.$emit('bv::table::refresh', 'organizations-table')
            },
            lastRowColspan() {
                var colspan = "3"
                if (this.reportType === 'moved') {
                    colspan = "5"
                }
                if (this.reportType === 'sold') {
                    colspan = "4"
                }
                if (this.reportType  === 'deleted') {
                    colspan = "4"
                }
                return colspan
            },
            lastRowTotalAmount() {
                if (this.reportType === 'added') {
                    return this.reportData.totalAddedBottleAmount
                } else {
                    return this.reportData.totalSoldBottleAmount
                }
            },
            lastRowTotalPurchasePrice() {
                if (this.reportType === 'added') {
                    if (this.reportData.totalAddedPurchasePriceAvgGenerated) {
                        return this.reportData.totalAddedPurchasePriceAvgGenerated.toFixed(2)
                    }
                } else {
                    if (this.reportData.totalPurchasePriceAvgGenerated) {
                        return this.reportData.totalPurchasePriceAvgGenerated.toFixed(2)
                    }
                }
            },
            lastRowTotalSellingPrice() {
                if (this.reportType === 'added') {
                    if (this.reportData.totalAddedRevenueBottle) {
                        return this.reportData.totalAddedRevenueBottle.toFixed(2)
                    }
                } else {
                    if (this.reportData.totalRevenueBottle) {
                        return this.reportData.totalRevenueBottle.toFixed(2)
                    }
                }
            },
            lastRowProfit() {
                if (this.reportType === 'added') {
                    if (this.reportData.totalAddedProfitGenerated) {
                        return this.reportData.totalAddedProfitGenerated.toFixed(2)
                    }
                } else {
                    if (this.reportData.totalProfitGenerated) {
                        return this.reportData.totalProfitGenerated.toFixed(2)
                    }
                }
            }
        },
        computed: {
            fields() { 
                var fields = [
                    {
                        key: 'createdAt',
                        label: this.$t('general.date'),
                        sortable: true
                    },
                    {
                        key: 'descriptionTitle',
                        label: this.$t('general.wine'),
                        sortable: true
                    },
                    {
                        key: 'changedByUser.username',
                        label: this.$t('general.employee'),
                        sortable: true
                    },
                    {
                        key: 'discount',
                        label: this.$t('detailPanel.discountModal.discountPercentage'),
                        sortable: false
                    },
                    {
                        key: 'soldAmount',
                        label: this.$t('general.amount'),
                        sortable: true
                    },
                    {
                        key: 'purchasePriceAvgGenerated',
                        label: this.$t('general.ek_short'),
                        sortable: true
                    },
                    {
                        key: 'revenueAll',
                        label: this.$t('general.vk_short'),
                        sortable: true
                    },
                    {
                        key: 'profitGenerated',
                        label: this.$t('general.profit'),
                        sortable: true
                    }
                ]
                if( this.reportType === 'moved' ) {
                    fields = [
                        {
                            key: 'venueWine.createdAt',
                            label: this.$t('reports.reportDetailModal.addedOn'),
                            sortable: true
                        },
                        {
                            key: 'createdAt',
                            label: this.$t('reports.reportDetailModal.movedOn'),
                            sortable: true
                        },
                        {
                            key: 'newVenueTitle',
                            label: this.$t('general.location'),
                            sortable: true
                        },
                        {
                            key: 'descriptionTitle',
                            label: this.$t('general.wine'),
                            sortable: true
                        },
                        {
                            key: 'changedByUser.username',
                            label: this.$t('general.employee'),
                            sortable: true
                        },
                        {
                            key: 'soldAmount',
                            label: this.$t('general.amount'),
                            sortable: true
                        },
                        {
                            key: 'purchasePriceAvgGenerated',
                            label: this.$t('general.ek_short'),
                            sortable: true
                        },
                        {
                            key: 'revenueAll',
                            label: this.$t('general.vk_short'),
                            sortable: true
                        },
                        {
                            key: 'profitGenerated',
                            label: this.$t('general.profit'),
                            sortable: true
                        }
                    ]
                } else if ( this.reportType === 'added' ) {
                    fields = [
                        {
                            key: 'createdAt',
                            label: this.$t('general.date'),
                            sortable: true
                        },
                        {
                            key: 'descriptionTitle',
                            label: this.$t('general.wine'),
                            sortable: true
                        },
                        {
                            key: 'changedByUser.username',
                            label: this.$t('general.employee'),
                            sortable: true
                        },
                        {
                            key: 'addedBottleAmount',
                            label: this.$t('general.amount'),
                            sortable: true
                        },
                        {
                            key: 'addedPurchasePriceAvgGenerated',
                            label: this.$t('general.ek_short'),
                            sortable: true
                        },
                        {
                            key: 'addedRevenueAll',
                            label: this.$t('general.vk_short'),
                            sortable: true
                        },
                        {
                            key: 'addedProfitGenerated',
                            label: this.$t('general.profit'),
                            sortable: true
                        }
                    ]
                } if( this.reportType === 'deleted' ) {
                    fields = [
                        {
                            key: 'createdAt',
                            label: this.$t('reports.reportDetailModal.movedOn'),
                            sortable: true
                        },
                        {
                            key: 'descriptionTitle',
                            label: this.$t('general.wine'),
                            sortable: true
                        },
                        {
                            key: 'changedByUser.username',
                            label: this.$t('general.employee'),
                            sortable: true
                        },
                        {
                            key: 'causeOfReduction',
                            label: this.$t('reports.reportDetailModal.causeOfReduction'),
                            sortable: false
                        },
                        {
                            key: 'soldAmount',
                            label: this.$t('general.amount'),
                            sortable: true
                        },
                        {
                            key: 'purchasePriceAvgGenerated',
                            label: this.$t('general.ek_short'),
                            sortable: true
                        },
                        {
                            key: 'revenueAll',
                            label: this.$t('general.vk_short'),
                            sortable: true
                        },
                        {
                            key: 'profitGenerated',
                            label: this.$t('general.profit'),
                            sortable: true
                        }
                    ]
                }

                return fields
            },
            getCurrency() {
                return getVenueCurrency(this.getVenue)
            },
            ...mapGetters(['getVenue'])
        },
        components: { LoadingAnimation }
    }
</script>
